import * as React from "react";
// @ts-ignore
import { injectIntl, Link } from "gatsby-plugin-intl";
import parse from "html-react-parser";
import { FC, useState } from "react";
import Analysis from "../../images/analysis.svg";
import ArrowUp from "../../images/arrow_up.svg";
import ArrowDown from "../../images/arrow_down.svg";
import Result from "../../images/result.svg";
import Download from "../../images/download.png";
import { useQuery } from "@apollo/client";
import { GET_ANALYSIS_TYPE } from "../../query/getAnalysis";

type AllAnalysisSidebarProps = {
  intl: any;
  setSearchTerm: any;
  setAnalysisType: any;
  analysisType: any;
};

const AllAnalysisSidebar: FC<AllAnalysisSidebarProps> = ({
  intl,
  setSearchTerm,
  setAnalysisType,
  analysisType,
}) => {
  const [toggled, setToggled] = useState(false);
  const { data } = useQuery(GET_ANALYSIS_TYPE, {
    variables: {
      locale: intl.locale,
    },
    fetchPolicy: "network-only",
  });
  const currentDate = new Date();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const formattedDate = `${currentDate.getDate()}.${month}.${currentDate.getFullYear()}`;

  const handleExportClick = async () => {
    const response = await fetch(
      `${process.env.STRAPI_BASE_URL}/api/export/analyses`,
      {
        method: "GET",
      }
    );
    const fileBlob = await response.blob();

    let link = document.createElement("a");
    // @ts-ignore
    link.href = window.URL.createObjectURL(fileBlob);
    link.download = `ПРЕЙСКУРАНТ ЦЕН ЛАБОРАТОРНЫХ УСЛУГ ДЛЯ КОРПОРАТИВНЫХ КЛИЕНТОВ ${formattedDate}.xlsx`;
    link.click();
    link.remove();
  };

  return (
    <div className=" flex sm:justify-end xs:justify-center">
      <div className="flex lg:w-[24rem] sm:w-[45rem] xs:w-[18rem] flex-col ">
        <div className="bg-[#D6E2F9] flex items-center justify-between h-[6rem] px-[1rem] border border-t-0 border-b-white border-x-0 rounded-t-[0.5rem]">
          <div className="grid grid-cols-2 items-center justify-between">
            <img src={Analysis} alt="" className="w-[4rem] h-[4rem]" />
            <p className="font-semibold text-[1.25rem] p-[0.2rem]">
              {intl.formatMessage({
                id: "ServiceMenu.AnalysisAndPrices.AnalysisAndPricesTitle",
              })}
            </p>
          </div>
          <img
            src={toggled ? ArrowDown : ArrowUp}
            alt=""
            className=" cursor-pointer"
            onClick={() => setToggled(!toggled)}
          />
        </div>
        <ul
          className={`flex flex-col bg-[#EDF2FC] bg-opacity-20 text-center ${
            toggled ? "toggled" : ""
          }`}
        >
          {data &&
            data.analysisTypes.data.map((item: any, index: any) => (
              <li
                className={
                  `flex items-center justify-left text-left text-[1rem] pl-[2rem] pr-[1rem] py-[1rem] font-semibold border border-y-white border-x-0  hover:pointer-events-auto cursor-pointer` +
                  " " +
                  (analysisType && analysisType.id === item.id
                    ? "bg-[#E2EAFA] border border-[#618EE4] border-l-[0.4rem] border-r-0 border-y-0 "
                    : "")
                }
                key={index}
                onClick={() =>
                  setAnalysisType({
                    id: item.id,
                    title: item.attributes.title,
                  }) && setSearchTerm("")
                }
              >
                {item.attributes.title}
              </li>
            ))}
        </ul>
        <Link to="/complex-service-packages">
          <div className="cursor-pointer mt-[1.5rem] rounded-[0.5rem] bg-[#FF922E] bg-opacity-25 flex items-center justify-between  h-[6rem] px-[1rem] border border-t-0 border-b-white border-x-0">
            <div className="grid grid-flow-col items-center justify-between">
              <img src={Result} alt="" className="w-[4rem] h-[4rem]" />
              <p className="font-semibold text-[1.25rem] ml-[2rem]">
                {parse(
                  intl.formatMessage({
                    id: `ServiceMenu.AnalysisAndPrices.ComplexesOfAnalyzes`,
                  })
                )}
              </p>
            </div>
          </div>
        </Link>
        <button>
          <div
            className="cursor-pointer mt-[1.5rem] rounded-[0.5rem] bg-[#FF922E] bg-opacity-25 flex items-center justify-between  h-[6rem] px-[1rem] border border-t-0 border-b-white border-x-0"
            onClick={handleExportClick}
          >
            <div className="grid grid-flow-col items-center justify-between">
              <img
                src={Download}
                alt=""
                className="w-[4rem] h-[4rem] p-[0.5rem]"
              />
              <p className="font-semibold text-[1.25rem] ml-[2rem]">
                {parse(
                  intl.formatMessage({
                    id: `ServiceMenu.AnalysisAndPrices.DownloadPrice`,
                  })
                )}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default injectIntl(AllAnalysisSidebar);
