import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import { useCallback, useEffect, useState } from "react";
import Search from "../images/search.svg";
import "../components/analysisAndPrices/AnalysisAndPrices.css";
import Layout from "../components/Layout";
import AllAnalysisSidebar from "../components/analysisAndPrices/AllAnalysisSidebar";
import AllAnalysisTable from "../components/analysisAndPrices/AllAnalysisTable";
// @ts-ignore
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import BreadCrumbs from "../BreadCrumbs";
import debounce from "lodash.debounce";

// @ts-ignore
const AnalysisAndPrices = ({ intl, search, pageContext }) => {
  const myKeysValues = typeof window !== "undefined" && window.location.search;

  const [analysisType, setAnalysisType] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermValue, setSearchTermValue] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        setAnalysisType(null);
      }
      // Send Axios request here
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (myKeysValues !== null) {
      const urlParams = new URLSearchParams(myKeysValues);
      if (urlParams.get("search") !== null) {
        setSearchTermValue(String(urlParams.get("search")) || "");
        setSearchTerm(String(urlParams.get("search")) || "");
      }
    }
  }, [myKeysValues]);

  const handleSearchTermChange = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const debouncedChangeHandler = useCallback(
    debounce(handleSearchTermChange, 300),
    []
  );

  return (
    <Layout>
      <div className="flex items-center justify-center bg-[#FAFAFA]">
        <div className="flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <div className="ml-[0.2rem] mt-[2.5rem] mb-[2rem] ">
            <BreadCrumbs
              // @ts-ignore
              intl={intl}
              pageContext={pageContext}
              title="ServiceMenu.AnalysisAndPrices.AnalysisAndPricesHeader"
            />
          </div>
          <div className=" mb-10  flex flex-col justify-between ">
            <div className="grid lg:grid-cols-2 sm:grid-cols-1 ">
              <h2 className="text-[2rem] text-[#001439] font-semibold">
                {intl.formatMessage({
                  id: "ServiceMenu.AnalysisAndPrices.AnalysisAndPricesHeader",
                })}
              </h2>
              <div className="lg:ml-[4rem] lg:my-[0rem] xs:my-[2.5rem]">
                <input
                  value={searchTermValue}
                  autoFocus
                  type="text"
                  autoComplete="off"
                  className="border border-2 rounded-[0.6rem] xl:w-[33rem] lg:w-[26rem] sm:w-[33rem] xs:w-[18rem] h-[3rem] pl-[1rem]"
                  placeholder={intl.formatMessage({
                    id: "ServiceMenu.AnalysisAndPrices.Placeholder.Search",
                  })}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchTermValue(e.target.value);
                    debouncedChangeHandler(e);
                  }}
                />
                <img src={Search} alt="" className="search" />
              </div>
            </div>

            <div className="w-[100%] flex  justify-between xs:flex-col sm:flex-col lg:flex-row">
              <AllAnalysisSidebar
                setSearchTerm={setSearchTerm}
                setAnalysisType={setAnalysisType}
                analysisType={analysisType}
              />
              <AllAnalysisTable
                analysisType={analysisType}
                searchTerm={searchTerm}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(AnalysisAndPrices);
