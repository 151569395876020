import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import parse from "html-react-parser";
import Calendar from "../../images/calendar_blue.svg";
import Cart from "../../images/cart_blue.svg";
import "./AnalysisAndPrices.css";
import { useContext } from "react";
import { AppStateContext } from "../Layout/Layout";
import { moneyFormatFromNumber } from "../../utils/formatter";
import { findItemInBasket, addToBasket } from "../../utils/helper-functions";

// @ts-ignore
const AllAnalysisTableRow = ({
  intl,
  analysisId,
  title,
  price,
  material,
  term,
}) => {
  // @ts-ignore
  const { setBasketCount } = useContext(AppStateContext);

  return (
    <div className="bg-white flex sm:flex-row xs:flex-col sm:items-center xs:items-baseline xl:w-[49.5rem] lg:w-[36rem] sm:w-auto sm:h-[11.875rem] xs:h-auto xl:justify-between lg:justify-evenly xs:justify-between text-center  items-center mb-2 overflow-hidden rounded-xl  sm:p-4 xs:p-[1rem] px-4 border border-[#EDF2FC] border-2  xs:text-[0.7rem] sm:text-sm lg:text-[0.8rem]">
      {/*Description*/}
      <div className="flex flex-col sm:w-[50%] xs:w-auto ">
        <h4
          className={`font-semibold sm:text-[${
            title.length > 60 ? "0.9" : "1.25"
          }rem] xs:text-[0.9rem] text-left`}
        >
          {parse(title)}
        </h4>
        {material && material.data ? (
          <div className="sm:mt-[2rem] xs:mt-[1.4rem] flex sm:items-center justify-left">
            <p
              className={`sm:text-[${
                title.length > 60 ? "0.8" : "1"
              }rem] xs:text-[0.9rem] font-medium`}
            >
              {intl.formatMessage({
                id: "ServiceMenu.AnalysisAndPrices.AllAnalysisTable.Material",
              })}
              :
            </p>
            <p
              className={`sm:text-[${
                title.length > 60 ? "0.8" : "1"
              }rem] xs:text-[0.9rem] font-bold pl-[4px]`}
            >
              {material.data.attributes.title}
            </p>
          </div>
        ) : null}
        <div
          className={`sm:mt-[${
            title.length > 60 ? "1.2" : "1.875"
          }rem] xs:mt-[1.4rem] flex sm:items-center justify-left`}
        >
          <img src={Calendar} alt="" className="" />
          <p
            className={`font-semibold text-[${
              title.length > 60 ? "0.8" : "1"
            }rem] pl-[8px]`}
          >
            {term}
          </p>
          <p
            className={`font-semibold text-[${
              title.length > 60 ? "0.8" : "1"
            }rem] pl-[4px]`}
          >
            {parse(
              intl.formatMessage({
                id: `ServiceMenu.AnalysisAndPrices.AllAnalysisTable.term`,
              })
            )}
          </p>
        </div>
      </div>
      {/*Price*/}
      <div className="xs:mt-[1.4rem] flex sm:flex-col sm:grid-flow-dense xs:grid xs:grid-flow-col sm:gap-0 xs:gap-[4rem] sm:w-[20%] xs:w-auto text-right">
        <h2 className="font-semibold sm:text-[2rem] xs:text-[1.5rem] text-[#001439] opacity-75">
          {moneyFormatFromNumber(price)}
          <span className="ml-[4px]">₸</span>
        </h2>

        <button
          className="lg:mt-[4rem] sm:mt-[5rem]  flex items-center justify-center lg:w-[9rem] xs:w-[8rem] h-[2.8rem] border border-[#618EE4] border-2 rounded-[0.6rem]"
          onClick={() => addToBasket(analysisId, setBasketCount)}
        >
          <img src={Cart} alt="" className="cart" />
          {findItemInBasket(analysisId) ? (
            <p className="font-semibold text-[1rem] text-[#618EE4] ml-[0.5rem]">
              {parse(
                intl.formatMessage({
                  id: `ServiceMenu.AnalysisAndPrices.InBasket`,
                })
              )}
            </p>
          ) : (
            <p className="font-semibold text-[1rem] text-[#618EE4] ml-[0.5rem]">
              {parse(
                intl.formatMessage({
                  id: `ServiceMenu.AnalysisAndPrices.AddToBasket`,
                })
              )}
            </p>
          )}
        </button>
      </div>
    </div>
  );
};

export default injectIntl(AllAnalysisTableRow);
