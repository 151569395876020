import * as React from "react";
import AllAnalysisTableRow from "./AllAnalysisTableRow";
import { injectIntl } from "gatsby-plugin-intl";
import { FC, useState } from "react";
import Pagination from "../Pagination";
import { useQuery } from "@apollo/client";
import { GET_ANALYSIS_BY_TYPE } from "../../query/getAnalysis";
// @ts-ignore

type AllAnalysisTableProps = {
  intl: any;
  analysisType: any;
  searchTerm: any;
};

const AllAnalysisTable: FC<AllAnalysisTableProps> = ({
  intl,
  analysisType,
  searchTerm,
}) => {
  const [page, setPage] = useState(0);

  const pageSize = 20;
  const variables = {
    ...(analysisType || searchTerm
      ? {
          filters: {
            covid: {
              eq: false,
            },
            ...(analysisType
              ? {
                  analysis_type: {
                    id: {
                      eq: analysisType.id,
                    },
                  },
                }
              : {}),
            ...(searchTerm
              ? {
                  or: [
                    {
                      description: {
                        containsi: searchTerm,
                      },
                    },
                    {
                      title: {
                        containsi: searchTerm,
                      },
                    },
                  ],
                }
              : {}),
          },
        }
      : {}),
    pagination: {
      page,
      pageSize,
    },
    locale: intl.locale,
  };

  const { loading, error, data } = useQuery(GET_ANALYSIS_BY_TYPE, {
    fetchPolicy: "network-only",
    variables,
  });

  if (loading) return intl.formatMessage({ id: "Loading.LoadingTitle" });

  if (error) return intl.formatMessage({ id: "Error.ErrorTitle" });

  return (
    <div className="lg:w-full">
      <div className="lg:ml-[2rem]">
        <div className="xs:mt-5 lg:w-full ">
          <p className="font-semibold text-[1.25rem] mb-[1.25rem] ml-[4px]">
            {(analysisType && analysisType.title) || ""}
          </p>
          <div>
            {data.analyses.data.map((analysis: any, index: any) => (
              <AllAnalysisTableRow
                analysisId={analysis.id}
                title={analysis.attributes.title}
                price={analysis.attributes.price}
                material={analysis.attributes.analysis_material}
                term={analysis.attributes.term}
                key={`analyses-${index}`}
              />
            ))}
          </div>
          <Pagination
            setPage={setPage}
            pagination={data.analyses.meta.pagination}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AllAnalysisTable);
